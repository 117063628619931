import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import feather from 'vue-icon';
import 'sanitize.css';

Vue.use(feather, 'v-icon');

import VueYoutube from 'vue-youtube';

Vue.use(VueYoutube);

import VueMeta from 'vue-meta';

Vue.use(VueMeta);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
