<template>
	<div id="home">
		<div class="thumbnail-background">
			<Transition name="thumbnail-blend">
				<div class="thumbnail" key="thumbnailOne" v-if="displayWhichThumbnail === 1" :style="{ 'backgroundImage': `url('${thumbnailOne}')` }"></div>
				<div class="thumbnail" key="thumbnailTwo" v-else-if="displayWhichThumbnail === 2" :style="{ 'backgroundImage': `url('${thumbnailTwo}')` }"></div>
			</Transition>
		</div>
		<div class="page-content-container">
			<div class="page-content-center">
				<div class="playlist-info-container">
					<div class="playlist-link-input">
						<input type="text" v-model="playlist" @keypress.enter="startNewPlaylist" :disabled="retrievingVideos" placeholder="Playlistlink eingeben...">
					</div>
					<button type="button" @click="startNewPlaylist" :disabled="retrievingVideos">
						<v-icon name="loader" v-if="retrievingVideos"></v-icon>
						<v-icon name="check" v-else></v-icon>
					</button>
				</div>
				<div class="youtube-player-container">
					<div class="player">
						<youtube :video-id="currentVideoID" :height="playerHeight" :width="playerWidth" :player-vars="playerVars" ref="yt" @ready="playerIsReady = true"></youtube>
					</div>
					<div class="player-sizer" ref="playerSizer"></div>
				</div>
				<div class="shuffle-controls-container">
					<!--					<button @click="resetWatchedVideos">-->
					<!--						Angeschaute Videos zurücksetzen-->
					<!--						<v-icon name="trash-2"></v-icon>-->
					<!--					</button>-->
					<button @click="shuffleToNewVideo">
						Nächstes Video
						<v-icon name="arrow-right"></v-icon>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import _ from 'lodash';

	// PlayerVars: https://developers.google.com/youtube/player_parameters#autoplay
	// iframe API: https://developers.google.com/youtube/iframe_api_reference#Retrieving_video_information

	export default {
		name: 'Home',
		data: () => ({
			playerInterval: null,
			playerIsReady: false,
			playerVars: {
				autoplay: 1
			},
			playlist: '',
			retrievingVideos: false,
			playerWidth: 200,
			playerHeight: 200,
			thumbnailOne: null,
			thumbnailTwo: null,
			displayWhichThumbnail: 1
		}),
		computed: {
			videos() {
				return this.$store.state.videos;
			},
			watchedVideos() {
				return this.$store.state.watchedVideos;
			},
			currentVideoID() {
				return this.$store.state.currentVideoID;
			},
			currentPlaylistID() {
				return this.$store.state.currentPlaylistID;
			},
			pageTitle() {
				if (this.currentVideoID) {
					const videoIndex = this.videos.findIndex((element) => {
						return element.videoID === this.currentVideoID;
					});

					const videoTitle = this.videos[videoIndex].title;

					return `YTshuffle | ${ videoTitle }`;
				}
				else {
					return 'YTshuffle';
				}
			}
		},
		methods: {
			shuffleToNewVideo() {
				return new Promise(async (resolve) => {
					const unwatchedVideos = this.videos.filter((element) => {
						const videoIndex = this.watchedVideos.findIndex((watchedElement) => {
							return element.videoID === watchedElement.videoID && watchedElement.playlistID === this.currentPlaylistID;
						});

						return videoIndex === -1;
					});

					if (unwatchedVideos.length >= 1) {
						const newRandomIndex = _.random(0, unwatchedVideos.length - 1);
						console.log('Drawing from', unwatchedVideos.length - 1, 'Videos and drew ', newRandomIndex);

						const videoID = unwatchedVideos[newRandomIndex].videoID;
						this.setNewThumbnail(videoID);

						this.$store.commit('setCurrentVideoID', videoID);
						resolve();
					}
					else {
						this.$store.commit('clearWatchedVideos', this.currentPlaylistID);
						await this.shuffleToNewVideo();
						resolve();
					}

				});
			},
			startNewPlaylist() {
				return new Promise(async (resolve) => {
					const playlistIDMatches = this.playlist.match(new RegExp('[a-zA-Z0-9\\-\\_]{34}', 'gi'));
					if (playlistIDMatches && playlistIDMatches.length >= 1) {
						const playlistID = playlistIDMatches[0];

						this.playlist = playlistID;

						if (this.$route.name !== 'HomeWithPlaylistID' || this.$route.params.playlistID !== playlistID) {
							await this.$router.replace({
								name: 'HomeWithPlaylistID',
								params: {
									playlistID: playlistID
								}
							});
						}

						await this.getVideos(playlistID);
					}
					else {
						alert('Es wurde keine PlaylistID gefunden.');
					}
					resolve();
				});
			},
			getVideos(playlistID) {
				return new Promise(async (resolve) => {
					this.retrievingVideos = true;

					axios({
						method: 'GET',
						url: `${ process.env.VUE_APP_API_PREFIX }/getVideos/${ playlistID }`
					}).then(async (response) => {
						const videos = response.data;
						if (videos.length >= 1) {
							this.$store.commit('setCurrentPlaylistID', playlistID);
							this.$store.commit('setVideos', response.data);
							await this.shuffleToNewVideo();
						}
						else {
							alert('Es wurden keine Videos in der Playlist gefunden oder die Playlist existiert nicht.');
							this.playlist = '';
						}

						resolve();
					}).catch((error) => {
						alert(`An error occurred. Please send this to the developer: \n\n${ error }`);
						resolve();
					}).finally(() => {
						this.retrievingVideos = false;
					});
				});
			},
			checkIfVideoFinished() {
				return new Promise(async (resolve) => {
					const time = await this.$refs.yt.player.getCurrentTime();
					const maxDuration = await this.$refs.yt.player.getDuration();
					const playerStatus = await this.$refs.yt.player.getPlayerState();

					if (maxDuration === -1 || playerStatus === 3) {
						resolve(false);
					}
					else {
						resolve(time >= maxDuration - 1);
					}
				});
			},
			checkPlayer() {
				return new Promise(async (resolve) => {
					if (this.playerIsReady && this.currentVideoID) {
						const isFinished = await this.checkIfVideoFinished();
						if (isFinished) {
							await this.shuffleToNewVideo();
						}
					}

					setTimeout(this.checkPlayer, 1000);
					resolve();
				});
			},
			calculatePlayerSize() {
				const sizerElement = this.$refs.playerSizer;
				const sizes = sizerElement.getBoundingClientRect();

				this.playerWidth = sizes.width;
				this.playerHeight = sizes.height;
			},
			getThumbnailByVideoID(videoID) {
				if (videoID) {
					const video = this.$store.state.videos.find((element) => {
						return element.videoID === videoID;
					});

					if (video) {
						return video.lowResThumbnail;
					}
					else {
						return null;
					}
				}
				else {
					return null;
				}
			},
			setNewThumbnail(videoID) {
				const newThumbnail = this.getThumbnailByVideoID(videoID);

				if (this.thumbnailOne == null) {
					this.thumbnailOne = newThumbnail;
					this.displayWhichThumbnail = 1;
				}
				else if (this.thumbnailTwo == null) {
					this.thumbnailTwo = newThumbnail;
					this.displayWhichThumbnail = 2;
				}
				else if (this.displayWhichThumbnail === 1) {
					this.thumbnailTwo = newThumbnail;
					this.displayWhichThumbnail = 2;
				}
				else if (this.displayWhichThumbnail === 2) {
					this.thumbnailOne = newThumbnail;
					this.displayWhichThumbnail = 1;
				}
			},
			resetWatchedVideos() {
				this.$store.commit('clearWatchedVideos');
			}
		},
		async mounted() {
			/* Restore PlaylistID from route (for bookmarking) */
			const routePlaylistID = this.$route.params.playlistID;

			if (routePlaylistID) {
				const playlistIDMatches = routePlaylistID.match(new RegExp('[a-zA-Z0-9\\-\\_]{34}', 'gi'));
				if (playlistIDMatches && playlistIDMatches.length >= 1) {
					const playlistID = playlistIDMatches[0];
					this.playlist = playlistID;
					await this.getVideos(playlistID);
				}
				else {
					await this.$router.replace({ name: 'Home' });
				}
			}

			await this.$store.dispatch('loadWatchedVideos');
			await this.checkPlayer();
			this.calculatePlayerSize();

			window.addEventListener('resize', this.calculatePlayerSize);
		},
		metaInfo() {
			return {
				title: this.pageTitle
			};
		}
	};
</script>

<style lang="scss" scoped>
	#home {
		position: relative;

		.thumbnail-background {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			filter: blur(200px);

			.thumbnail {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
			}
		}

		.page-content-container {
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: transparent;
			//backdrop-filter: blur(200px) brightness(0.7);
			display: flex;
			justify-content: center;
			align-content: center;
			padding: 0 10px;

			.page-content-center {
				max-width: 900px;
				width: 900px;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.playlist-info-container {
					width: 100%;
					padding: 1rem;
					background: #00000038;
					border-radius: 10px;
					display: flex;
					box-shadow: 0 3px 10px #00000052;

					.playlist-link-input {
						flex: 1;
						padding-right: 1rem;

						input {
							font-size: 1.5rem;
							background: #00000038;
							border: none;
							color: #efefef;
							padding: 0.5rem 1rem;
							border-radius: 7px;
							outline: none;
							transition: 0.3s cubic-bezier(0, 0.5, 0.5, 1);
							width: 100%;
							height: 100%;

							&:hover {
								background: #00000080;
							}
						}
					}

					button {
						background: #00000038;
						border: none;
						color: #efefef;
						padding: 0.5rem 1rem;
						border-radius: 7px;
						cursor: pointer;
						transition: 0.3s cubic-bezier(0, 0.5, 0.5, 1);

						&:hover {
							background: #00000080;
						}

						.icon.icon-loader {
							animation: spin 1s infinite linear;
						}
					}
				}

				.youtube-player-container {
					width: 100%;
					position: relative;
					margin: 2rem 0;

					.player {
						position: absolute;
						top: 0;
						left: 0;
						height: calc(100% - 1px);
						width: 100%;
						border-radius: 10px;
						overflow: hidden;
						box-shadow: 0 3px 10px #0000007d;
						background: black;
					}

					.player-sizer {
						width: 100%;
						aspect-ratio: 16 / 9;
						border-radius: 10px;
					}
				}

				.shuffle-controls-container {
					width: 100%;
					border-radius: 10px;
					display: flex;
					justify-content: flex-end;
					flex-wrap: wrap;

					button {
						background: #00000038;
						border: none;
						color: #efefef;
						padding: 0.5rem 1rem;
						border-radius: 7px;
						cursor: pointer;
						transition: 0.3s cubic-bezier(0, 0.5, 0.5, 1);
						display: flex;
						align-items: center;
						justify-content: space-between;
						box-shadow: 0 3px 10px #00000040;
						margin: 0.2rem 0;

						&:hover {
							background: #00000080;
						}

						.icon {
							margin-left: 1rem;
						}
					}
				}
			}
		}
	}

	.thumbnail-blend {
		&-enter,
		&-leave-to {
			opacity: 0;
		}

		&-enter-active,
		&-leave-active {
			transition: 2s ease-in-out;
		}
	}

	svg.icon {
		height: 2rem;
		width: 2rem;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
</style>
