import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import localforage, { removeItem } from 'localforage';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		videos: [],
		watchedVideos: [],
		currentPlaylistID: '', //PLuxJUESHlqvVi1m0sYPsjBHhowNt375-c //PLv3TTBr1W_9tppikBxAE_G6qjWdBljBHJ
		currentVideoID: null
	},
	mutations: {
		setVideos(state, payload) {
			state.videos = payload;
		},
		setCurrentVideoID(state, payload) {
			const videoID = payload;

			const videoIndex = state.watchedVideos.findIndex((element) => {
				return element.videoID === videoID && element.playlistID === state.currentPlaylistID;
			});

			if (videoIndex === -1) {
				state.watchedVideos.push({
					videoID: videoID,
					playlistID: state.currentPlaylistID,
					timestamp: new Date().getTime()
				});

			}

			state.currentVideoID = videoID;

			localforage.setItem('watched-videos', state.watchedVideos);
		},
		clearWatchedVideos(state, payload) {
			state.watchedVideos = state.watchedVideos.filter((element) => {
				return element.playlistID !== payload;
			});

			localforage.setItem('watched-videos', state.watchedVideos);
		},
		setWatchedVideos(state, payload) {
			state.watchedVideos = payload;
		},
		setCurrentPlaylistID(state, payload) {
			state.currentPlaylistID = payload;
		}
	},
	actions: {
		loadWatchedVideos(context) {
			return new Promise(async (resolve) => {
				const watchedVideos = await localforage.getItem('watched-videos');

				if (_.isArray(watchedVideos)) {
					context.commit('setWatchedVideos', watchedVideos);
				}

				resolve();
			});
		}
	},
	modules: {}
});
